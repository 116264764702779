
import { Component, Prop, Vue } from 'vue-property-decorator';
import ValidInfo from './ValidInfo.vue';

@Component({
  components: {
    ValidInfo,
  },
})
export default class ValidTooltip extends Vue {
  @Prop({ required: true }) readonly validityReasons!: Array<Polkadot.Validity>;

  @Prop({ required: true }) readonly valid!: boolean;
}
