
import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import Candidate from './Candidate.vue';

@Component({
  components: {
    Candidate,
  },
})
export default class MainInfo extends Vue {
  @Prop({ required: true }) readonly chain!: string;

  @Prop({ required: true }) readonly candidates!: Array<Polkadot.Candidate>;

  get totalActive(): number {
    return _.sumBy(this.candidates, (c) => (c.active ? 1 : 0));
  }

  get averageInclusion(): number {
    return _.meanBy(this.candidates, 'inclusion');
  }

  get averageCommission(): number {
    return _.meanBy(this.candidates, 'commission');
  }

  get totalFaults(): number {
    return _.sumBy(this.candidates, (c) => (c.faults));
  }
}
