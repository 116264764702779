
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { RootState } from '@/store';
import _ from 'lodash';
import Candidate from './Candidate.vue';
import MainInfo from './MainInfo.vue';
import ValidTooltip from './ValidTooltip.vue';

@Component({
  components: {
    Candidate, MainInfo, ValidTooltip,
  },
})
export default class CandidatesList extends Vue {
  @State('loading') loading!: RootState['loading'];

  @Prop({ required: true }) readonly chain!: string;

  @Prop({ required: true }) readonly candidates!: Array<Polkadot.Candidate>;

  private search: string = '';

  private headers = [
    { text: 'Rank', value: 'rank' },
    { text: 'Name', align: 'left', value: 'name' },
    { text: 'Commission', align: 'center', value: 'commission' },
    { text: 'Faults', align: 'center', value: 'faults' },
    { text: 'Discovered', align: 'center', value: 'discoveredAt' },
    { text: 'Nominated (Era)', align: 'center', value: 'nominatedAt' },
    { text: 'Inclusion', align: 'center', value: 'inclusion' },
    { text: 'Active', align: 'center', value: 'active' },
    { text: 'Valid', align: 'center', value: 'valid' },
    { text: 'Score', align: 'center', value: 'total' },
  ]

  // eslint-disable-next-line class-methods-use-this
  public handleClickRow(item: Polkadot.Candidate): void {
    this.$router.push({ name: this.chain, params: { stash: item.stash } });
  }

  public filterOnlyCapsText(value: string, search: string, item: Polkadot.Candidate): boolean {
    return _.includes(item.name, search);
    // return value != null &&
    //   search != null &&
    //   typeof value === 'string' &&
    //   value.toString().toLocaleUpperCase().indexOf(search) !== -1
  }
}
