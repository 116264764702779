
import { Component, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import Candidate from '@/components/Home/Candidate.vue';
import api from '@/api';

@Component({
  components: {
    Candidate,
  },
})
export default class CandidateDashboard extends Vue {
  get stashAccount(): string {
    return _.get(this.$route.params, 'stash');
  }

  get chain(): string {
    return _.get(this.$route.meta, 'chain');
  }

  private candidate?: Polkadot.Candidate | null = null;

  private loaded = false;

  async mounted(): Promise<void> {
    try {
      if (this.chain === 'polkadot') {
        this.candidate = await api.getPolkadotCandidate(this.stashAccount);
      } else if (this.chain === 'kusama') {
        this.candidate = await api.getKusamaCandidate(this.stashAccount);
      }
    }
    catch(e) {
      console.info(`${this.stashAccount} not in candidate list`);
    }
    finally {
      this.loaded = true;
    }
  }
}
