
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ScoreProgressBar extends Vue {
  @Prop({ required: true }) readonly value!: number;
  @Prop({ required: false, default: 100 }) readonly max!: number;

  get percentage(): number {
    return this.$options.filters?.percentageOver(this.value, this.max, 2);
  } 

  public getColor(): string {
    if(this.percentage < 25) return "red lighten-2";
    else if(this.percentage < 75) return "blue lighten-2";
    else return "green lighten-2";
  }
}
