/* eslint-disable object-curly-newline */
import Vue from 'vue';
import { createLogger, default as Vuex } from 'vuex';
import _ from 'lodash';
import api from '../api';

Vue.use(Vuex);

export interface RootState {
  loading: boolean;
  pCandidates: unknown;
  kCandidates: unknown;
}

const store = new Vuex.Store({
  state: {
    loading: false,
    pCandidates: [],
    kCandidates: [],
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload.value;
    },
    setPolkadot(state, payload) {
      if (payload.candidates) {
        state.pCandidates = payload.candidates;
      }
    },
    setKusama(state, payload) {
      if (payload.candidates) {
        state.kCandidates = payload.candidates;
      }
    },
  },
  actions: {
    async loadPolkadot(context) {
      context.commit('setLoading', { value: true });
      const candidates = await api.getPolkadotCandidates();
      context.commit('setPolkadot', { candidates: _.orderBy(candidates, 'rank', 'desc') });
      context.commit('setLoading', { value: false });
    },
    async loadKusama(context) {
      context.commit('setLoading', { value: true });
      const candidates = await api.getKusamaCandidates();
      context.commit('setKusama', { candidates: _.orderBy(candidates, 'rank', 'desc') });
      context.commit('setLoading', { value: false });
    },
  },
  modules: {
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [process.env.NODE_ENV !== 'production' ? createLogger() : _.noop],
});

export default store;
