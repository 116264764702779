
import { Component, Prop, Vue } from 'vue-property-decorator';
import ScoreProgressBar from './ScoreProgressBar.vue';
import _ from 'lodash';

import polkadotScores from "@/assets/polkadot-score.json";
import kusamaScores from "@/assets/kusama-score.json";

const dotScores = _.assign(polkadotScores, { randomness: 1.15, aggregate: _.sum(_.values(polkadotScores)) });
const ksmScores = _.assign(kusamaScores, { randomness: 1.15, aggregate: _.sum(_.values(kusamaScores)) });

@Component({ components: {
  ScoreProgressBar,
  }})
export default class ScoreBreakdown extends Vue {
  @Prop({ required: true }) readonly score!: Polkadot.Score;

  @Prop({ required: true }) readonly chain!: string;

  public getMax(field: string): number {
    return this.chain === "polkadot" ?
      _.get(dotScores, field, 100) : _.get(ksmScores, field, 100);
  }

  public getSuggestion(field: string, value: number): string {
    const max = this.getMax(field);
    const percentage = _.toNumber(this.$options.filters?.percentageOver(value, max, 2));
    if(percentage > 74) return "";

    const suggestions = {
      spanInclusion: "Just wait :)",
      inclusion: "Just wait :)",
      bonded: "Increase self bond!",
      nominated: "Just wait :)",
      faults: "Be more consistent with online status",
      nominations: "You need more votes from stakers!",
      location: "Try a server that is not shared with other validators",
      provider: "Try a less known provider for your server",
      region: "Try a different region for your server",
      country: "Try a different country for your server"
    }

    return _.get(suggestions, field, "");
  }
}
