import axios, { Method, AxiosRequestConfig } from 'axios';
import _ from 'lodash';
// import EventSource from 'eventsource';

const { hostname } = window.location;
const port = process.env.VUE_APP_SRV_PORT || 3000;

let urlApi = `http://${hostname}:${port}/api`;
if (process.env.NODE_ENV === 'production') {
  urlApi = '/api';
}

const clientApi = axios.create({
  baseURL: urlApi,
  responseType: 'json',
});

const executeApi = async (
  method: Method,
  url: string,
  data?: unknown,
  moreConfig?: AxiosRequestConfig,
) => {
  let config = { method, url, data };
  if (moreConfig) {
    config = _.assign(config, moreConfig);
  }
  const req = await clientApi(config);
  return req.data;
};

export interface ContactMessageData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export interface DonationMessageData {
  value: number;
  memo: string;
}

export default {
  // Get candidates
  async getPolkadotCandidates(): Promise<Array<Polkadot.Candidate>> {
    return executeApi('GET', '/polkadot');
  },

  async getPolkadotCandidate(stash: string): Promise<Polkadot.Candidate> {
    return executeApi('GET', `/polkadot/${stash}`);
  },

  async getKusamaCandidates(): Promise<Array<Polkadot.Candidate>> {
    return executeApi('GET', '/kusama');
  },

  async getKusamaCandidate(stash: string): Promise<Polkadot.Candidate> {
    return executeApi('GET', `/kusama/${stash}`);
  },

  /**
   * Telegram API
   */
  async sendContactNotification(data: ContactMessageData): Promise<{ sent: boolean, error?: string}> {
    return executeApi('POST', '/contact', data);
  },

  async sendDonationNotification(data: DonationMessageData): Promise<{ sent: boolean, error?: string}> {
    return executeApi('POST', '/donation', data);
  },
};
