
import { Component, Prop, Vue } from 'vue-property-decorator';
import Identity from './Identity.vue';
import Location from './Location.vue';
import Server from './Server.vue';
import Software from './Software.vue';
import VailidityBreakdown from './VailidityBreakdown.vue';
import ScoreBreakdown from './ScoreBreakdown.vue';
import ValidInfo from './ValidInfo.vue';

import _ from 'lodash';

@Component({
  components: {
    Identity, Location, Server, Software, VailidityBreakdown, ScoreBreakdown, ValidInfo,
  },
})
export default class Candidate extends Vue {
  @Prop({ required: true }) readonly candidate!: Polkadot.Candidate;

  @Prop({ required: true }) readonly chain!: string;

  public invalidityExist(): boolean {
    console.info('invalidityExist', _.some(this.candidate.validity, ['valid', false]));
    return _.some(this.candidate.validity, ['valid', false]);
  }
}
